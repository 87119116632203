.chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .chart-container {
      padding: 10px;
    }
  }
  